<template>
  <div>
    <h3 ref="intro">
      What is a Triangle?
    </h3>
    <br>
    <p>
      A triangle is a shape that is obtained by connecting three points on the same plane with straight lines. <br><br>

      The three points are called vertices of the triangle, and three straight lines
      connecting these points are called sides of the triangle.
    </p>
    <h3 ref="intro">
      Area of a Triangle
    </h3>
    <br>
    <p>
      The area of a triangle is the span of the region enclosed between the three sides of the triangle.
      <br> <br>
      For a triangle with height \(h\) and base \(b\), the area is given by
      $$A = \frac{1}{2} \times b \times h$$
    </p>

    <h3 ref="pg">
      MagicGraph | Area of a Triangle (Test Your Knowledge)
    </h3>
    <br>
    <h5> Getting Started </h5>
    <p>
      This MagicGraph offers a visual-interactive illustration to help you test your understanding of the area of a triangle. <br><br>
      Follow the steps below to get started.
      <ul class="a mx-3">
        <li>
          Tap on <i class="shuffler ma-1" /> button to
          interactively changed the base, height and area of the triangle. Or alternately you can
          change the triangle by dragging points A, B or C.
        </li>
        <li> Enter your answer for the area of the triangle in the input field shown.</li>
        <li> Tap on <i class="tester ma-1" /> button to test your answer. </li>
      </ul>
    </p>
    <h5> Icons on the MagicGraph </h5>
    <p>
      This MagicGraph contains the following two icons: <br>
      <i class="shuffler ma-1" /> Tap on this button to shuffle and generate a new version of the problem. <br>
      <i class="tester ma-1" /> Tap on this button to test your answer. <br>
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'TriangleArea',
  async created () {
    await this.$store.dispatch('navigation/getData');
    let title = 'Area of a Triangle';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Area',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
